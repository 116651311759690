import gameScene from './gameScene'
import gameSetupScene from './gameSetupScene'
import joinStartScene from './joinStartScene'
import joinWaitScene from './joinWaitScene'
import loseScene from './loseScene'
import mainMenuScene from './mainMenuScene'
import playerSelectionScene from './playerSelectionScene'
import prefScene from './prefScene'
import startScene from './startScene'
import startBattleScene from './startBattleScene'
import startCampaignScene from './startCampaignScene'
import timeupScene from './timeupScene'
import disconnectedScene from './disconnectedScene'

export default {
    gameScene, 
    gameSetupScene,
    joinStartScene,
    joinWaitScene,
    loseScene, 
    mainMenuScene,
    playerSelectionScene,
    prefScene,
    startScene,
    startBattleScene,
    startCampaignScene,
    timeupScene,
    disconnectedScene,
}